import * as THREE from "three";
import {CSS3DObject} from "three/examples/jsm/renderers/CSS3DRenderer";
import {ANNOTATION_LAYER_CHANEL, VIDEO_LAYER_CHANEL} from "../../globalConstants/GlobalConstants";

class VideoDisplay {
    videoScreen;
    videoElm;
    _isPlaying = false;
    videoButton;
    videoScreenInit = false;

    constructor(mediaRecord, mesh, scene, css3dScene, videosGroup,waitCount) {

        this.mediaRecord = mediaRecord;
        this.mesh = mesh;
        this._mediaId = mediaRecord.paintingId;
        this.scene = scene;
        this.css3dScene = css3dScene;

        const spriteMapPlay = new THREE.TextureLoader().load(mediaRecord.videoPlaySpriteImageURL);
        const spriteMapPause = new THREE.TextureLoader().load(mediaRecord.videoPauseSpriteImageURL);

        this.spriteMaterialPlay = new THREE.SpriteMaterial({map: spriteMapPlay, color: 0xffffff, fog: true});
        this.spriteMaterialPause = new THREE.SpriteMaterial({map: spriteMapPause, color: 0xffffff, fog: true});

        this.videosGroup = videosGroup;

        this.calcPosition(waitCount);
    }

    calcPosition = (waitCount)=> {
        const boundingBox = this.mesh.geometry.boundingBox;
        // const center = boundingBox.getCenter(new THREE.Vector3())
        const boxSize = boundingBox.getSize(new THREE.Vector3());
        const videoRotation = this.mesh.quaternion.clone();
        let worldPos = new THREE.Vector3();
        let worldPosVideoButton = new THREE.Vector3();


        //console.log("videoDisplay=", worldPos, center, boxSize, videoRotation, boundingBox);

        this.videoElm = document.getElementById(this.mediaRecord.standaloneVideoElementId);
        this.videoElm.src = this.mediaRecord.videoURL;
        const texture = new THREE.VideoTexture(this.videoElm);
        texture.minFilter = THREE.NearestFilter;
        texture.magFilter = THREE.NearestFilter;
        texture.encoding = this.mediaRecord.videoTextureEncoding;

        const parameters = {color: 0xffffff, map: texture, side: THREE.DoubleSide};

        const geometry = new THREE.PlaneBufferGeometry(boxSize.x, boxSize.y);
        // const material = new THREE.MeshLambertMaterial( parameters );
        // const material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide});
        const material = new THREE.MeshBasicMaterial(parameters);
        this.videoScreen = new THREE.Mesh(geometry, material);
       // this.videoScreen.applyQuaternion(videoRotation);

        let offsetPos
        if (this.mediaRecord.videoAutoPlay === 'Y') {
            offsetPos = new THREE.Vector3(0, 0, boxSize.z+.001);
        }
        else {
           offsetPos = new THREE.Vector3(0, 0, boxSize.z + 0.001);
        }
        const offsetPosVideoButton = new THREE.Vector3(0, 0, boxSize.z + 0.1);
        this.mesh.add(this.videoScreen);

        this.videoScreen.position.copy(offsetPosVideoButton);
        this.videoScreen.getWorldPosition(worldPosVideoButton);
        this.videoScreen.position.copy(offsetPos);
        this.videoScreen.getWorldPosition(worldPos);
        this.videoScreen.visible = false;

        if (this.mediaRecord.videoAutoPlay === 'N') {
            const videoButtonPos = this.mediaRecord.videoButtonPos || worldPosVideoButton.clone();
            this.videoButton = this.createVideoButton(videoButtonPos, boxSize);
            this.videosGroup.add(this.videoButton);
        }

        if (this.mediaRecord.displayMesh === 'N') {

            this.mesh.remove(this.videoScreen);
            this.videoScreen.position.copy(worldPos);
            this.videoScreen.applyQuaternion(videoRotation);
            this.scene.add(this.videoScreen);

        }

        if (this.mediaRecord.videoAutoPlay === 'Y') {
            setTimeout(() => {
                    this.autoPlay();
                    console.log("start video:",this.mediaRecord.standaloneVideoElementId)
                },
                5000 + waitCount * 1000);
        }
    }



    autoPlay = ()=> {

        const self = this;
        this.videoElm.loop = true;
        setTimeout(() => {
            if (this.videoElm.readyState >= 2) {
                this.initVideoScreen();
                this.videoElm.play();
                this._isPlaying = true;

               /* this.videoElm.addEventListener('ended', function() {
                    this.currentTime = 0;
                    this.play();
                    console.log("video replay",self.mediaRecord.standaloneVideoElementId);
                }, false);*/

            }
            else {
                this.autoPlay();
            }
        },1000);


    }



    initVideoScreen = () => {
        if (this.videoScreenInit) return;
        this.videoScreen.visible = true;
        this.videoScreenInit = true;
    }


    playVideo = () => {

        console.log("playVideo is pressed");
        if (this.isPlaying) {
            return;
        }

        //iOS doesnt play with this condition test
       /* setTimeout(() => {
            if (this.videoElm.readyState >= 2) {*/
                this.initVideoScreen();
                this.videoElm.play();
                this._isPlaying = true;
                this.videoButton.material = this.spriteMaterialPause;

          /*  }
            else {
               this.playVideo();
            }
        },1000);*/
    }

    PauseVideo = () => {
        if (!this.isPlaying) {
            return;
        }
        this.videoElm.pause();
        this._isPlaying = false;
        this.videoButton.material = this.spriteMaterialPlay;
    }


    get isPlaying() {
        return this._isPlaying;
    }


    createVideoButton = (videoButtonPos, boxSize) => {

        const sprite = new THREE.Sprite(this.spriteMaterialPlay);
        const spriteScale = this.mediaRecord.videoButtonScale || new THREE.Vector3(.1,.1,.1);
        sprite.scale.copy(spriteScale);
        sprite.userData = this.mediaRecord.paintingId;
        // videoButtonPos.setY(videoButtonPos.y - boxSize.y / 2 - .2);
        if (!this.mediaRecord.videoButtonPos) {
            videoButtonPos.setX(videoButtonPos.x + boxSize.x / 2 + .2);
        }
        sprite.position.copy(videoButtonPos);
        sprite.layers.set(VIDEO_LAYER_CHANEL);
        return sprite;
    }

    createCss3dVideoPlayer(width, height) {
        const div = document.createElement('div');
        div.style.width = `${width}px`;
        div.style.height = `${height}px`;
        div.style.backgroundColor = '#000';
        const video = document.createElement('iframe');
        video.style.width = `${width}px`;
        video.style.height = `${height}px`;
        video.style.border = '0px';
        video.src = ['https://www.youtube.com/embed/', 'SJOz3qjfQXU', '?rel=0'].join('');

        /* const video = document.createElement('video');
         video.style.width = `${width}px`;
         video.style.height = `${height}px`;
         video.style.border = '0px';


         const sourceMP4 = document.createElement("source");
         sourceMP4.type = "video/mp4";
         sourceMP4.src = this.mediaRecord.videoURL;
         video.appendChild(sourceMP4);*/


        div.appendChild(video);

        const object = new CSS3DObject(div);
        object.scale.set(.001, .001, 1);

        return object;

    }


    get mediaId() {
        return this._mediaId;
    }
}

export default VideoDisplay;
