import * as THREE from 'three'
import {COLLIDER_LAYER_CHANEL} from '../../globalConstants/GlobalConstants'
import ColliderDataController from "./ColliderDataController";

class ColliderContainer {
    constructor(scene, camera, colliders) {
        this.scene = scene;
        this.colliders = colliders;
        this.camera = camera;
        this._colliderGroup = new THREE.Group();
        this.scene.add(this._colliderGroup);
        this.init();
    }

    init() {
        this.colliders.forEach((node) => {
            node.layers.set(COLLIDER_LAYER_CHANEL);
            this._colliderGroup.add(node);
            if (node.userData.collider) {
                const rec = ColliderDataController.getColliderDataById(node.userData.collider);
                if (rec) {
                    node.visible = rec.displayCollider === "Y";
                }
            }
            //console.log("colliderContainer init node=",node.name);
        });
        this.camera.layers.enable(COLLIDER_LAYER_CHANEL);

    }

    get colliderGroup() {
        return this._colliderGroup;
    }
}

export default ColliderContainer;
