
import AnnotationDataController from "./AnnotationDataController";

class MediaController{
    constructor(annotationContainer,videoContainer) {
        this.annotationContainer = annotationContainer;
        this.videoContainer = videoContainer;
    }

    static getNodeRecord = (node) => {
        return AnnotationDataController.getAnnotationDataById(node.userData.painting_id);
    }
    addMedia = (node) => {
        const mediaRecord = MediaController.getNodeRecord(node);
        if (mediaRecord) {
            if (mediaRecord.isAnnotation) {
                this.annotationContainer.addAnnotationToMesh(node, mediaRecord);
            }
            if (mediaRecord.isStandaloneVideo) {
                this.videoContainer.addVideoDisplay(mediaRecord,node);
            }

            if (mediaRecord.displayMesh === 'N' && !mediaRecord.isStandaloneVideo) {
                node.visible = false;
            }


        }
    }

    addMediaWithNoParent = () => {
        const records = AnnotationDataController.getAnnotationsWithNoParent();
        records.forEach(mediaRecord => {
            if (mediaRecord.isAnnotation) {
                this.annotationContainer.addAnnotationToMesh(null, mediaRecord);
            }

        })
    }


}

export default MediaController;
