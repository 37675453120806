import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import ReactPlayer from "react-player";
import Carousel from "react-bootstrap/Carousel";

import ReactGa from 'react-ga';

class AnnotationModal extends Component {
    //annotationURL;
    constructor(props) {
        super(props);

        this.state = {
            // annotationURL: '',
            isShowInSitu: false,
        }

        this.showInSituDialogueHandler = this.showInSituDialogueHandler.bind(this);
        this.inSituCloseBtnHandler = this.inSituCloseBtnHandler.bind(this);
        // this.onHideHandler = this.onHideHandler.bind(this);
    }

    onShow = () => {
        // this.annotationURL = this.props.annotationURL;
        this.setState({
            // ...this.state,
            ...this.props.annotationData
        })

        //This is part of the Google Analytics Module
        // console.log("ReactGa", this.props.annotationData.paintingId);
        ReactGa.event({
            category: 'Annotation',
            action: `User clicked an annotation ${this.props.annotationData.paintingId}`,
        })
    }

    onSendEmail = () => {
        window.location = this.state.EnquireEmail;
    }

    gotoPage = (urlLink) => {
        window.open(urlLink, '_blank');
    }

    shareEmail = () => {
        window.location = this.state.shareEmail;

    }

    showInSituDialogueHandler = () => {
        //console.log("[AnnotationModal], isShowInSitu", this.state.isShowInSitu);
        this.setState((state) => ({isShowInSitu: true}))
    }

    inSituCloseBtnHandler = () => {
        this.setState({isShowInSitu: false});
        //todo: I need to see how to make the dialog not
        // close and go back to the parent AnnotationModal but on the description page
    }

    render() {
        let buyNow = null;
        let sendTo = null;
        // let pipe1 = null;

        let enquire = <a href={this.state.EnquireEmail}
                         onClick={() => this.onSendEmail()}>ENQUIRE</a> /*+ this.state.isSold === 'N' ?  `&nbsp;|&nbsp` : ''*/;


        console.log("[AnnotationModal], shareEmail", this.state.shareEmail);
        // if (this.state.isSold === 'N') {
        //     pipe1 = <span>&nbsp;|&nbsp;</span>;
        //     buyNow = <a href={this.state.ContactURL} target="_blank" onClick={() => this.gotoPage()}>BUY NOW</a>;
        //     sendTo = <a href={this.state.shareEmail} target="_blank" onClick={() => this.shareEmail()}>SEND TO</a>
        //
        // }

        if (this.state.isSold === 'N') {
            // pipe1 = <span>&nbsp;|&nbsp;</span>;
            buyNow = <div style={{display: "inline"}}>
                <span>&nbsp;|&nbsp;</span>
                <a href={this.state.ContactURL} target="_blank" onClick={() => this.gotoPage(this.state.ContactURL)}>BUY NOW</a>
            </div>;
            sendTo = <div style={{display: "inline"}}>
                <span>&nbsp;|&nbsp;</span>
                <a href={this.state.shareEmail} target="_blank" onClick={() => this.shareEmail()}>SEND TO</a>
            </div>


        }


        /* SendTo email Button */
        if(this.state.shareEmail) {
            sendTo = <div style={{display: "inline"}}>
                <span>&nbsp;|&nbsp;</span>
                <a href={this.state.shareEmail} target="_blank" onClick={() => this.shareEmail()}>SEND TO</a>
            </div>
        }

        let inSituLink = null;

        if (this.props.annotationData && (this.props.annotationData.isInSitu === "Y")) {
            // console.log("[AnnotationModal], isInSitu", this.props.annotationData.paintingId, this.props.annotationData.isInSitu, this.state.imageUrl);
            inSituLink = <a href="#" onClick={this.showInSituDialogueHandler}>IN-SITU</a>;
        }

        let artistInspirationUrl = null;
        let pipe3 = null;
        if(this.props.annotationData && this.props.annotationData.artistInspirationUrl) {
            if(inSituLink) {
                pipe3 = <span>&nbsp;|&nbsp;</span>;
            }

            artistInspirationUrl = <a href={this.props.annotationData.artistInspirationUrl} target="_blank" onClick={() => this.gotoPage(this.props.annotationData.artistInspirationUrl)}>ARTIST INSPIRATION</a>
        }

        let displayCarouselItems = null;
        if (this.props.annotationData && this.props.annotationData.images.length > 0) {
            displayCarouselItems = this.props.annotationData.images.map((image, index) => {
                return (
                    <Carousel.Item key={image}>
                        <img
                            className="d-block w-100"
                            src={process.env.PUBLIC_URL + '/paintings/paintings_insitu/' + image}
                            alt="First slide"
                        />
                    </Carousel.Item>
                )
            })
        }


        if (this.state.isShowInSitu) {
            /* Show the In Situ Page*/
            // console.log("[AnnotationModal] images length", this.props.annotationData.images.length);
            return (
                <div className="annotation-modal">
                    <Modal
                        dialogClassName={"primaryModal modal-dialog annotation-modal-classname modal-dialog-scrollable"}
                        show={this.state.isShowInSitu} /*onShow={() => this.onShow()} */
                        onHide={this.inSituCloseBtnHandler} size={this.props.annotationModalSize} centered={true}
                        backdrop="static">
                        <Modal.Header closeButton style={{border: 'none'}}>
                        </Modal.Header>
                        <Modal.Body>

                            <Carousel>
                                {displayCarouselItems}
                            </Carousel>


                        </Modal.Body>
                    </Modal>
                </div>
            )

        } else {

            return (

                <div className="annotation-modal">
                    <Modal
                        dialogClassName={"primaryModal modal-dialog annotation-modal-classname modal-dialog-scrollable"}
                        show={this.props.showAnnotationModal} onShow={() => this.onShow()}
                        onHide={this.props.hideAnnotationModal} size={this.props.annotationModalSize} centered={true}
                        backdrop="static">
                        <Modal.Header closeButton style={{border: 'none'}}>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className="annotation-main-container-modal">
                                    <div className="annotation-media-container">
                                        <div className="annotation-image-wrapper">
                                            {this.state.isAnnotationVideo ?
                                                <video width="100%" controls>
                                                    <source src={this.state.videoURL} type="video/mp4"/>
                                                    <source src={this.state.videoURL} type="video/ogg"/>
                                                    Your browser does not support HTML video.
                                                </video> :
                                                <img
                                                    src={this.state.imageUrl} alt="Annotation painting section"/>
                                            }
                                        </div>
                                    </div>
                                    <div className="annotation-text-container">
                                        <div className="annotation-header-text-container"
                                             dangerouslySetInnerHTML={{__html: this.state.headerText}}>
                                        </div>
                                        <div className="annotation-body-text-container">
                                            <p dangerouslySetInnerHTML={{__html: this.state.bodyText}}></p>
                                        </div>
                                    </div>
                                    <div className="annotation-contact-container">

                                        <h6>
                                            {/*{enquire} {pipe1} {buyNow} {pipe1} {sendTo}*/}
                                            {enquire} {buyNow} {sendTo}
                                        </h6>
                                        <h6>
                                            {inSituLink} {pipe3} {artistInspirationUrl}
                                        </h6>

                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>

                /* */
            )

        }


    }


}

export default AnnotationModal;
