
import {ANNOTATION_VIDEO_DISPLAY,ANNOTATION_PARENT} from '../../globalConstants/GlobalConstants';
import * as THREE from 'three';
const ANNOTATION_CONTACT_TEXT = 'geraldine@kahn-gallery.com';

const AnnotationsDB = [
   /*

    {
       /* paintingId: 'W04L01',
        videoId: 'W04L01',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L01.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L01',
        videoAutoPlay:'Y',

    },
    {
        paintingId: 'W04L02',
        videoId: 'W04L02',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L02.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L02',
        videoAutoPlay:'Y',

    },
    {
        paintingId: 'W04L03',
        videoId: 'W04L03',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L03.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L03',
        videoAutoPlay:'Y',

    },
    {
        paintingId: 'W04L04',
        videoId: 'W04L04',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L04.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L04',
        videoAutoPlay:'Y',

    },
    {
        paintingId: 'W04L05',
        videoId: 'W04L05',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L05.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L05',
        videoAutoPlay:'Y',

    },
    {
        paintingId: 'W04L06',
        videoId: 'W04L06',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L06.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L06',
        videoAutoPlay:'Y',

    },
    {
        paintingId: 'W04L07',
        videoId: 'W04L07',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L07.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L07',
        videoAutoPlay:'Y',

    },
    {
        paintingId: 'W04L08',
        videoId: 'W04L08',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L08.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L08',
        videoAutoPlay:'Y',

    },

    {
        paintingId: 'W04L09',
        videoId: 'W04L09',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L09.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L09',
        videoAutoPlay:'Y',

    },
    {
        paintingId: 'W04L10',
        videoId: 'W04L10',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L10.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L10',
        videoAutoPlay:'Y',

    },
    {
        paintingId: 'W04L11',
        videoId: 'W04L11',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L11.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L11',
        videoAutoPlay:'Y',

    },

    {
        paintingId: 'W04L12',
        videoId: 'W04L12',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04L12.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W04L12',
        videoAutoPlay:'Y',

    },*/

    {
        paintingId: 'W05V01',
        videoId: 'W05V01',
        displayType: ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W05V01.mp4',
        headerText: 'Sample',
        bodyText: null,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'Y', // //todo : add this property to global variables
        displayHeight:90,
        standaloneVideoElementId:'W05V01',
        videoAutoPlay:'N',
        videoButtonScale: {x:.2,y:.2,z:.2},
        videoButtonPos:{x:2.89,y:-10.34,z:-0.42},
        videoPlaySpriteImageURL: '/assets/sprites/videoPlayButtonBlack.svg',
        videoPauseSpriteImageURL: '/assets/sprites/videoPauseButtonBlack.svg',
        videoTextureEncoding: THREE.sRGBEncoding,

    },
   /* {
        paintingId: 'W04P01',
        videoId: null,
        videoURL: null,
        headerText: '<h2>"289"</h2><h4>Adolfo Bimer, 2017</h4>',
        bodyText: `59” x 35” x 4” Impresión digital sobre papel, pinzas y roscas metálicas <br> ED 3+AP (disponibles 2/3 3/3 AP) <br> $2000 USD`,
        imageURL:`/paintings/W04P01.jpg`,
        annotationScale: {x:.1,y:.1,z:1},
        /!*  annotationPos:{x:5.6,y:1.8,z:12.086},
          annotationRotation: {x:0,y:0,z:0},*!/
        spriteImageURL:`/assets/sprites/AnnotationsIRed.svg`,
    },*/
];



export {AnnotationsDB, ANNOTATION_CONTACT_TEXT};
