import React, {Component, createRef} from 'react';
import HDRIViewer from "./HDRIViewer";
import './HDRIViewer.css'

class HDRIViewerComp extends Component {

    hDRIViewer = null;

    constructor(props) {
        super(props);
        this.mount = React.createRef();
    }

    componentDidMount() {
        this.hDRIViewer = new HDRIViewer(this.mount.current);
        //this.hDRIViewer.createTestCube();
    }

    render() {
        return (
            <div>
                <div className="produced-by-v21artspace">V21 ARTSPACE</div>
                <div className="HDRIViewerClass" ref={this.mount}>Hello World!</div>
            </div>
        )
    }

}

export default HDRIViewerComp;
